.form-control-bordered {
  border-color: goldenrod !important;
  font-size: 0.725rem;
}

.form-control-bordered {
  .select-container .select__control {
    border-color: goldenrod !important;
    font-size: 0.725rem;
    min-height: 45px;
  }
}
.upload-image-dealer {
  .image-upload-image {
    background-size: contain;
    border-radius: 0%;
  }
}

.jsonObject {
  height: 120px;
  min-height: 120px;
  max-height: 120px;
}

.form-control-bordered {
  Select {
    border-color: goldenrod !important;
    font-size: 0.725rem;
  }
}

.form-control-bordered.is-invalid {
  font-size: 0.725rem;
  border-color: #dc3545 !important;
  background-image: none;
}

.form-inline-dealer {
  margin-left: 0;
  svg {
    position: relative !important;
    right: 0px !important;
    top: 0px !important;
  }
  .file-upload {
    border: 1px solid goldenrod !important;
    width: 450px !important;
  }
  .form-file {
    border: 1px solid goldenrod !important;
    min-height: 50px;
    vertical-align: middle;
    .form-control-file {
      margin-top: 12px;
    }
  }
}

.padding-zero {
  padding: 0px !important;
  .select-container .select__menu {
    z-index: 9999 !important;
    position: relative;
  }
}

.div-align-end {
  text-align: end;
}

.create-button {
  margin-left: 8.2rem;
  width: 150px !important;
}

.create-button-hide-list {
  margin-left: 6.5rem;
  width: 150px !important;
}

.save-button {
  width: 150px !important;
  margin-left: 6.5rem;
}

.back-color {
  tr {
    background-color: #dc3545 !important;
  }
  td {
    background-color: wheat !important;
  }
}
.invite-button {
  margin-left: 2.5rem;
  width: 180px !important;
}
.invite-button-hide-list {
  margin-left: 0.5rem;
  width: 180px !important;
}
.invite-model-label {
  max-width: 100%;
}

.search-label {
  width: 100%;
}

.form-inline-dealer label {
  justify-content: left !important;
  font-size: 0.725rem;
}

.form-inline-dealer-role {
  font-size: 0.725rem;
  .select-container {
    width: 58%;
  }
}

.margin-input {
  margin-left: 40px;
}

.margin-input-select {
  margin-left: 25px;
}

.table-dealer-content {
  background-color: $white;
  thead {
    th {
      border-bottom: 1px solid $gray-line;
      font-size: 0.725rem;
    }
  }
  tbody {
    td {
      background-color: $white;
      cursor: pointer;
      font-size: 0.725rem;
    }
  }
}

.image-upload-control-bordered {
  width: 420px;
  position: relative;
  cursor: pointer;

  .img-upload-btn {
    width: auto !important;
    height: 32px;
    line-height: rem(16px);
    font-size: 12px;
    font-weight: 800;
    letter-spacing: rem(1px);
    text-align: center;
  }

  .input-text {
    position: relative;
    width: 100%;
    .input-control {
      width: 100%;
    }
  }

  .form-control {
    cursor: pointer;
  }

  .browse-btn {
    position: relative;
    left: 8px;
  }
}

.div-switch {
  font-size: 0.625rem;
  .custom-control-label {
    font-size: 0.725rem;
    padding-left: 0px;
    text-transform: uppercase;
    letter-spacing: 0.0325rem;
    color: #798795;
  }
  .switch-label {
    left: 22px;
  }
}
